html {
  box-sizing: border-box;
}

.grid {
  display: grid;
  /* min-height: 80vh; */
  padding: 0 0 40px 0;
  gap: 40px 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 995px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 40px 20px;
  }
}

@media (max-width: 560px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 40px 20px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  line-height: 1.35 !important;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  margin: 0;
  height: 100vh;
}

.section-container {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  @media (max-width: 576px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.container {
  /* Extra small devices (phones, 600px and down) */
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 480px) {
  .container {
    max-width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-left: 27px;
    padding-right: 27px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1340px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575.98px) {
  .row {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 576px) {
  .header-col {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 576px) {
  .header-col {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .header-col {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 767px) {
  .header-col-top {
    padding-top: calc(5rem + 66px);
    padding-bottom: 3rem;
  }
}

/* @media (min-width: 768px) {
  .header-col-top {
    padding-top: calc(100px + 66px);
    padding-bottom: 100px;
  }
} */

@media (min-width: 768px) {
  .header-col-top {
    padding-top: calc(7.5rem + 66px);
    padding-bottom: 5rem;
  }
}

.content-container {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 768px) {
  .content-container {
    display: flex;
    flex: 0 0 85%;
    max-width: 85%;
  }
}

.content-showhover-container {
  padding-top: 2rem;
}

.text-center {
  text-align: center !important;
}
.text-gap {
  margin-bottom: 2rem !important;
}

.hero {
  overflow: hidden;
  position: relative;
  background-color: #000;
  display: flex;
  padding-top: 220px;
  padding-bottom: 250px;
  flex-direction: column;
}

@media (max-width: 767px) {
  .hero {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
}

@media (min-width: 992px) {
  .hero {
    min-height: 100vh;
  }
}

@media (min-width: 576px) {
  .hero-experience-overlay {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

.container-son-content {
  display: flex;
  position: relative;
  z-index: 10;
  margin-left: auto !important;
  margin-right: auto !important;
}

.container-son-content {
  @media (min-width: 768px) {
    width: 720px;
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-son-content {
    width: 960px;
    max-width: 960px;
    padding-left: 27px;
    padding-right: 27px;
  }
}

@media (min-width: 1024px) {
  .container-son-content {
    width: 960px;
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-son-content {
    width: 1240px;
    max-width: 1240px;
  }
}

@media (min-width: 1800px) {
  .container-son-content {
    width: 1340px;
    max-width: 1340px;
  }
}

.background-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-position: 50%;
  object-fit: cover;
  @media (max-width: 576px) {
    object-fit: contain;
  }
}

.article-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-position: 50%;
  object-fit: contain;
}

.title {
  font-size: calc(46px + 78 * ((100vw - 320px) / 1680)) !important;
  line-height: 120%;
}

.patron-background {
  height: 100%;
  width: 100%;
  background-position: 50%;
  background-repeat: 'no-repeat';
  background-position: 'bottom';
}

.navbar-container {
  display: flex;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 90%;
  max-width: 90%;
}

.appear {
  animation: appear 500ms ease 1;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.container-text-content {
  display: flex;
  position: relative;
  z-index: 10;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 75%;
  max-width: 1080px;
  padding-left: 27px;
  padding-right: 27px;
}

@media (max-width: 500px) {
  .container-text-content {
    width: 100%;
    max-width: 100%;
  }
}

.view-detail__scroll-view::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}

.view-detail__scroll-view {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* mozilla */
}

.coverflowEffect-container-content > .swiper {
  overflow: visible !important;
}
