.hero-experience-title {
  margin: 0 auto;
  text-align: left;
  flex-basis: 0;
  flex-grow: 1;
  /* padding-right: 15px;
  padding-left: 15px; */
  /* @media only screen and (min-width: 768px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  } */

  @media only screen and (min-width: 576px) {
    max-width: 100%;
    position: relative;
    width: 100%;
  }
}

.hero-experience-presentation {
  flex-direction: row-reverse;
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.hero-experience-content {
  border-top: 2px solid #fff;
  position: relative;
  color: #fff;
  margin-top: 8rem;
  width: 100%;
  margin-right: 0;
}

@media (max-width: 575px) {
  .hero-experience-content {
    margin: 40px 0 0 0px;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
}

@media only screen and (min-width: 768px) {
  .hero-experience-content {
    width: 40%;
    margin-right: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-experience-content {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .hero-experience-content {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

@media only screen and (min-width: 1024px) {
  .hero-experience-content {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}
