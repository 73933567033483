.main {
  width: 100%;
  height: 100vh;

  position: relative;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: brightness(0.4);
  object-fit: cover;
  z-index: 1;
}

.content {
  flex-direction: column;
  z-index: 2;
  gap: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content a {
  text-decoration: none;
  color: inherit;
}
